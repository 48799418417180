import React, { Component } from 'react'
import ImgData from './imagedata'
import BeforeAfterSlider from 'react-before-after-slider'
import './style.css'


class SliderApp extends Component{
    constructor(props){
        super(props)
        this.state={
            index: 0,
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillReceiveProps(props) {
        this.setState({ index: props.onImgChange })
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    
    render() {

        const current = ImgData[this.state.index]


        const aspectRatio = current.imageorientation === 'Portrait' ? 9/16: 16/9
        //const widthC = Math.min(520 - 32, this.state.width)
        //const heightC = Math.min(380, this.state.height)

        const widthC = this.state.width > 600 ? this.state.width / 2 - 20 : this.state.width -10
        const heightC = Math.min(560, this.state.height)


        const heightR = widthC / aspectRatio
        const widthR = heightC * aspectRatio

        const width = aspectRatio > 0 ? Math.min(widthC, widthR) : Math.min(heightC, heightR)
        const height = aspectRatio > 0 ? Math.min(heightC, heightR) : Math.min(widthC, widthR) 



        return (
            <div>
                <div className = "SliderContainerDiv">
                    <div>
                    <BeforeAfterSlider
                        className="SliderContainer"
                        before={current.before.src}
                        after={current.after.src}
                        width={width}
                        height={height}
                    /></div>
                </div>
            </div>
        )
    }

}

export default SliderApp
