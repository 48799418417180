const ImgData= [
    {
        before: {
            src: ('/Photos/1_1.jpg'),
            
        },
        after: {
            src: ('/Photos/1_2.jpg'),
            
        },
        imageorientation: 'Landscape',
        title: 'Toronto Streets',
        description: 'Before the real city could be seen it had to be imagined, the way rumours and tall tales were a kind of charting."- Michael Ondaatje, In the Skin of a Lion',
    },
    {
        before: {
            src: ('/Photos/2_1.jpg'),

        },
        after: {
            src: ('/Photos/2_2.jpg'),

        }
        ,
        imageorientation: 'Portrait',
        title: 'The Gooderham Building',
        description: 'The Gooderham Building, an example of Romanesque Revival and French Gothic architecture, has remained an iconic site since 1892."- Alix Hall',
    },
    {
        before: {
            src: ('/Photos/3_1.jpg'),

        },
        after: {
            src: ('/Photos/3_2.jpg'),

        },
        imageorientation: 'Landscape',
        title: 'The Cold Walk',
        description: 'Nothing is more mysterious than watching a lonely man who is taking for a night walk in a foggy street!"- Mehmet Murat ildan',
    },
    {
        before: {
            src: ('/Photos/4_1.jpg'),

        },
        after: {
            src: ('/Photos/4_2.jpg'),

        },
        imageorientation: 'Landscape',
        title: 'Toronto Skyline',
        description: 'High tech, low life"- Mike Pondsmith',
    },
]

export default ImgData;