import React from "react";
import styled from "styled-components";

import { motion } from "framer-motion";

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 40;
  height: 40;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 40px;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#0D0C1D" : "white")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) =>
        open ? "translateX(5px) rotate(45deg)" : "rotate(0)"};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) =>
        open ? "translateX(5px) rotate(-45deg)" : "rotate(0)"};
    }
  }
`;



const Burger = ({ open, setOpen }) => {

  return (  
    <motion.div
            animate={{
              borderRadius: ["20%", "20%", "50%", "50%", "20%"]
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              loop: Infinity,
              repeatDelay: 1
            }}
            style = {{
              background: "linear-gradient(135deg, gold, red)",
              borderRadius: "27.5px",
              width: "55px",
              height: "55px",
               position: "fixed",
               top: "1.5rem",
               right: "2rem",
               zIndex: "2",
               justifyContent: "space-around",
               display: "flex",
               flexDirection: "column",
               alignItems: "center"
            }}
          >
      <StyledBurger
        open={open}
        onClick={() => setOpen(!open)}
        style={{
          width: 40,
          height: 40, position: "fixed"
        }}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
    </motion.div>
  );
};

export default Burger;


