import React, { useState } from "react";
import PCVersion from "./PC-Man-SVG";
import Lappie from "./PC-Screen-SVG";
import { motion } from "framer-motion";

export default function App() {
  const [clicked, setClicked] = useState(false);

  const propsHandleClicked = () => {
    setClicked(true);
  };
  const msgHandleDisappear = {
    opacity: clicked ? "0" : "1"
  };

  const PCvariants = {
    active: {
      x: "300px",
      y: "50px",
      scale: 0.75,
      backgroundColor: "rgba(255,255,255,0)",
      backdropFilter: "blur(0)"
    },
    inActive: {
      scale: 1.5,
      y: "150px",
      backgroundColor: "rgba(255,255,255,0.5)",
      borderRadius: "15px",
      backdropFilter: "blur(2px)"
    }
  };

  const Lappievariants = {
    active: {
      scale: 1.2,
      opacity: 1,
      y: "150px",
      x: "100px"
    },
    inActive: {
      scale: 0
    }
  };
  const mainStyle = {
    textAlign: "center",
    justifyContent: "center",
    margin: "0"
  };

  return (
    <div style={mainStyle}>
      <div style={{ width: "1200px", height: "900px", margin: "0 auto" }}>
        <motion.div
          animate={clicked ? "active" : "inActive"}
          variants={Lappievariants}
          style={{
            opacity: 0,
            position: "absolute",
            margin: "0 auto",
            display: "flex",
            flexWrap: "wrap"
          }}
        >
          <Lappie handleClicked={propsHandleClicked} />
        </motion.div>
        <motion.div
          animate={clicked ? "active" : "inActive"}
          variants={PCvariants}
          style={{ display: "inline-block" }}
        >
          <PCVersion
            handleClicked={propsHandleClicked}
            handleDisappear={msgHandleDisappear}
          />
        </motion.div>
      </div>
    </div>
  );
}
