import React from "react";
import { motion } from "framer-motion";
import "./Mobile-Screen-SVG.css";

export default function App() {
  return (
    <div style={{ width: "100%" }}>
      <motion.div className="mainDiv">
        <motion.h1 className="title1">
          Hello visitor! {" "}
          <span role="img" aria-label="Hello">
            🙋‍♂️
          </span>
        </motion.h1>
        <motion.h2 className="title2">I'm Shrey Shah,</motion.h2>
        <motion.h2 className="title3">
          a mechanical engineer and data analyst.
        </motion.h2>
        <motion.div className="title4">
          <p>
            An engineer who has the technical knowledge and experience of data
            analytics and working experience of 3D machine design engineering.
          </p>
          <p>
            I am a hardcore tech follower, a photography lover, a part-time
            gamer, and obsessed with astronomy.
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
}
