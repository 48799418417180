import * as React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

export default function Icon(props) {
  const StyledLink = styled(motion.a)`
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #f1f1f1;
    margin: 10px;
    border-radius: 30%;
    box-shadow: 0 5px 15px -5px #00000070;
    color: ${props.bgcolor};
    overflow: hidden;
    position: relative;
    text-align: center;

    ::before {
      content: "";
      position: absolute;
      width: 120%;
      height: 120%;
      background: linear-gradient(0deg,${props.bgcolor},#01004a);
      transform: rotate(45deg);
      left: -110%;
      top: 90%;
      z-index: -1;
    }
    i {
      line-height: 90px;
      font-size: 26px;
      transition: 0.2s linear;
    }
    :hover i {
      transform: scale(1.3);
      color: #f1f1f1;
    }
    :hover::before {
      animation: flash 0.5s 1;
      top: -10%;
      left: -10%;
    }
    @keyframes flash {
      0% {
        left: -100%;
        top: 90%;
      }
      50% {
        left: 10%;
        top: -30%;
      }
      100% {
        left: -10%;
        top: -10%;
      }
    }
  `;

  return (
    <StyledLink
      href={props.link}
      whileHover={{
        scale: 1.2,
        color: "#f1f1f1"
      }}
      whileTap={{ scale: 0.9, color: props.bgcolor }}
      rel="noreferrer"
      target="_blank"
      initial={{ color: props.bgcolor }}
    >
      <motion.i style={{ lineHeight: "60px", fontSize: "40px" }}>
        {props.passIcon}
      </motion.i>
    </StyledLink>
  );
}
