import React from "react";
import styled from "styled-components";

import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';

import HomeApp from "../Home/App";
import AboutApp from "../About/App";
import GalleryApp from "../photography/app";
import SkillsApp from "../skills/App";
import WorkApp from "../Work/App";

const StyledMenu = styled.nav`
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform-origin: top right;
      transform: ${({ open }) => (open ? "scaleX(1)" : "scaleX(0)")};
      height: 100vh;
      width: 400px;
      text-align: center;
      position: fixed;
      top: 0;
      right: 0;
      transition: transform 0.3s ease-in-out;
      z-index: 1;
      overflow-x: hidden;
      border-radius: 10px;
      background: linear-gradient(222deg, #64f38c, #30e8bf, #00c3ff);
      background-size: 500% 500%;

      -webkit-animation: menuAnim 20s ease infinite;
      animation: menuAnim 20s ease infinite;

      @-webkit-keyframes menuAnim {
      0%{background-position:49% 0%}
      50%{background-position:52% 100%}
      100%{background-position:49% 0%}
      }
      @keyframes menuAnim {
      0%{background-position:49% 0%}
      50%{background-position:52% 100%}
      100%{background-position:49% 0%}
      }
  

  @media (max-width: 576px) {
    width: 100%;
  }



  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`;

const Menu = ({ open, setOpen }) => {
  return (
        <Router>

      <Switch>

          <Route path="/Contact">
            <AboutApp />
          </Route>

          <Route path="/Studio">
            <GalleryApp/>
          </Route>

          <Route path="/Skills">
            <SkillsApp />
          </Route>

          <Route path="/Work">
            <WorkApp />
          </Route>

          <Route path="/">
            <HomeApp />
          </Route>

      </Switch>



    <StyledMenu open={open}>
      <Link to="/" onClick={() => setOpen(!open)}>
        <span role="img" aria-label="Home">
          🏠
        </span>
        Home
      </Link>
      <Link to="/Skills" onClick={() => setOpen(!open)}>
        <span role="img" aria-label="Skills">
          🤹🏻
        </span>
        Skills
      </Link>
      <Link to="/Work" onClick={() => setOpen(!open)}>
        <span role="img" aria-label="Work">
          👨‍💻
        </span>
        Work
      </Link>
      <Link to="/Studio" onClick={() => setOpen(!open)}>
        <span role="img" aria-label="Studio">
          📸
        </span>
        Studio
      </Link>
      <Link to="/Contact" onClick={() => setOpen(!open)}>
        <span role="img" aria-label="Contact">
          📇
        </span>
        Contact
      </Link>
    </StyledMenu></Router>
  );
};

export default Menu;
