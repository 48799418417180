import React from 'react';
import ProgressDiv from './knowledgebars/progressDiv'
import SunburstGraph from './skillschart/SunburstApp'
import Grid from '@material-ui/core/Grid'
import {motion} from 'framer-motion';

import TagManager from 'react-gtm-module'
const args = {
  gtmId: 'GTM-W8WXLHH',
  dataLayer: {
    event: "SkillsVisit",
  },
  event: 'gtm.js',
  dataLayerName: "PageDataLayer"
};
TagManager.dataLayer(args);

export default function App() {
  return (

    <div style={{textAlign: "center"}}>
    <Grid container>

      <Grid item sm={12} md={6} xs={12}>
        <motion.div
          initial={{scale:0}}
        animate={{scale:1}} transition={{ease: "easeOut", duration: 0.3 }}>
          <SunburstGraph/>
        </motion.div>
      </Grid>

      <Grid item sm={12} md={6} xs={12}>
        <motion.div
          initial={{scale:0}}
        animate={{scale:1}} transition={{ease: "easeOut", duration: 0.3 }}>
        <ProgressDiv />
        </motion.div>
      </Grid>
      
    </Grid>

    </div>

  );
}

