import React from "react";
import Burger from "./Burger";
import Menu from "./SliderMenu";


const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) return;
      handler(event);
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

function App() {
  const [open, setOpen] = React.useState(false);
  const node = React.useRef();
  useOnClickOutside(node, () => setOpen(false));
  return (
    <div>
      <div ref={node}>
        <div>
          <Burger open={open} setOpen={setOpen} />
        </div>
        <Menu open={open} setOpen={setOpen} />
      </div>
    </div>
  );
};

export default App;
