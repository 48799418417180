export const items = [
  {
    id: "a",
    category: "Kaggle Competition",
    title: "Predicting the COVID-19 result using Logistic Regression",
    externalLink: "https://towardsdatascience.com/predicting-the-probability-of-sars-cov-2-result-using-multiple-logistic-regressing-in-r-and-python-874cf9185d62",
    referenceDate: "May 2020",
    details: "Classifying the SARS CoV-2 patients and what variables affect the result."
  },
  {
    id: "f",
    category: "Open-Source Project",
    title: "CoinBase-Pro API library in Python",
    externalLink: "https://github.com/ZeroPanda/CoinBase-Pro-API",
    referenceDate: "Sept 2020",
    details: "The unofficial Python client for the Coinbase Pro API."
  },
  {
    id: "e",
    category: "Personal Project",
    title: "Real-time Reddit VADER Sentiment Analysis",
    externalLink: "https://towardsdatascience.com/real-time-reddit-vader-sentiment-analysis-for-term-tracking-in-worldnews-subreddit-bbd366d477c0",
    referenceDate: "July 2020",
    details: "Collecting Reddit Data using Streaming Reddit API with PRAW, POSTGRESQL, and Dash in Python."
  },
  {
    id: "g",
    category: "Academic Project",
    title: "Toronto Safe Roads Competition Toronto Police Service & GEOTAB",
    externalLink: "https://github.com/ZeroPanda/Toronto-Safe-Roads-Competition/blob/main/SafeRoadsPresentation.pdf",
    referenceDate: "March 2020",
    details: "Provided the location for the most fatal roads in GTA and     recommendations to prevent accidents."
  },
  {
    id: "b",
    category: "Internship Project",
    title: "Simulation Working Model of Turret Lathe in 3D",
    externalLink: "https://grabcad.com/library/6-jaw-vertical-turrret-lathe-bed-1",
    referenceDate: "March 2017",
    details: "Diameter of 4500mm. 6 jaws and 6 resting blocks. Combination of bevel gears and worm gears for setting up the jaws."
  },
  {
    id: "d",
    category: "Academic Project",
    title: "Simulating Production Process-flows in Plant Simulation",
    externalLink: "https://drive.google.com/file/d/1Cm071zJ52j54_D13RwAM6GjTRjrP0T63/view?usp=sharing",
    referenceDate: "Aug 2019",
    details: "Provide a new shop floor layout considering space for the raw materials, work in progress and finished products using simulation Tecnomatix; and I4.0 recommendations."
  },
  {
    id: "c",
    category: "Academic Project",
    title: "All Wheel Steering System",
    externalLink: "https://drive.google.com/file/d/0B-Ul8QC3BT-YVzV6bjFrMy1iQ1pPcElTTkdfQTBxOE5hY1hj/view?usp=sharing",
    referenceDate: "December 2016",
    details: "Four wheel steering is a method developed in automobile industry for the effective turning of the vehicle and to increase the maneuverability. In a typical front wheel steering system the rear wheels do not turn in the direction of the curve and thus curb on the efficiency of the steering. In four wheel steering the rear wheels turn with the front wheels thus increasing the efficiency of the vehicle."
  },
  
];
