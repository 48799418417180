import React, { Component } from 'react'
import ImgData from './imagedata' 
import './style.css'

class imageDetail extends Component{
    constructor(props) {
        super(props)
        this.state = {
            index: 0
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ index: props.onImgChange })
    }

    render(){
        const current = ImgData[this.state.index]
        return(
            <div>
                <h1 className="titleFonts">{current.title}</h1> 
                <h2 className="descFonts">"{current.description}</h2><br/>
            </div>

        )
    }
}

export default imageDetail