import React from 'react';
import ProgressBlock from './progressBlock';
import SkillData from './skillsdata.json';

const divStyle = {
      maxWidth: '800px',
      margin: '0 auto',
      columnWidth: '14em',
      columnGap: '2em',
      columnRule: '1px solid #777'
}


export default function App(props) {
      
      const data = SkillData[props.indexNo].subcategory
      const selectColor = '#9F' + (props.indexNo + 3) + 'D21'
      
  return (
        <>
    <div style={divStyle}>

      {data.map((skill, index) => (
            <ProgressBlock key={index} color={selectColor} fillvalue = {skill.confidence} skillname={skill.name}/>
      ))}
      

    </div>
    </>
  );
}


