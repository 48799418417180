import React from 'react';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px"
  },
  progress: {
    background: "rgba(0,0,0,0.1)",
    justifyContent: "flex-start",
    borderRadius: "100px",
    alignItems: "center",
    position: "relative",
    padding: "0 5px",
    display: "flex",
    height: "30px",
    width: "100%"
  },

  progressvalue: {
    animation: "$load 5s normal forwards",
    boxShadow: "0 10px 20px -6.5px #000",
    borderRadius: "100px",
    background: (props) => props.color,
    height: "20px",
    width: "0",
    maxWidth: (props) => props.fillvalue
  },

  "@keyframes load": {
    "0%": { width: "0" },
    "100%": { width: "100%" }
  }
});

export default function ProgressBar(props) {

  const propsStyle = {
    color: props.color,
    fillvalue: props.fillvalue
  };

  const classes = useStyles(propsStyle);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.progress}>
          <div className={classes.progressvalue}></div>
        </div>
      </div>
    </>
  );
}