import React from 'react';
import Menu from './Menu/App';
import BackgroundP from './Background/Particles-background';
//import BackgroundV from './Background/vanta-background';

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-W8WXLHH',
  dataLayer: {
      event: "FirstVisit"
    },
    event: 'gtm.js',
    dataLayerName: "PageDataLayer"
}
TagManager.initialize(tagManagerArgs)


function App() {
  
  return (
    <div>
      <BackgroundP />
      < Menu/>

    </div>
  )
}

export default App;


