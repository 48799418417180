import React, { useState } from 'react'
import SliderApp from './slider'
import {
    Grid,
    GridList, 
    GridListTile, 
    GridListTileBar, 
    MuiThemeProvider,
    createMuiTheme}
    from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import ImgData from './imagedata'
import './style.css'
import before_after_logo from './static/media/before_after.png'
import ImageDetail from './ImageDetail'
import {motion} from 'framer-motion';

import TagManager from 'react-gtm-module'
const args = {
    gtmId: 'GTM-W8WXLHH',
    dataLayer: {
        event: "StudioVisit",
    },
    event: 'gtm.js',
    dataLayerName: "PageDataLayer"
};
TagManager.dataLayer(args);

const THEME = createMuiTheme({
    typography: {
        "fontFamily": "inherit"
    }
});


const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around'
    },
    gridList: {
        overflowY: 'auto',
    }
});


function initialIndex(){
    return 0
}

function ImgApp() {
    const classes = useStyles
    const [indexState, setIndexState] = useState(initialIndex())


    
    function callBackImageOnChange(index){
        setIndexState(index)
    }

    return(
        <>
        <div>
            <MuiThemeProvider theme={THEME}>

            <motion.div
                initial={{scale:0}}
                animate = {
                    {
                        scale: 1
                    }
                }
                transition = {
                    {
                        ease: "easeOut",
                        duration: 0.3,
                        delay: 0.3
                    }
                }
                style = {
                    {
                        backgroundColor: "rgba(255,255,255,0.5)",
                        borderRadius: "15px",
                        backdropFilter: "blur(2px)",
                        marginLeft: "5%",
                        marginRight: "5%"
                    }
                } >

                    <ImageDetail onImgChange={indexState} />

                </motion.div>

            


            <motion.div
                initial={{scale:0.2}}
                animate={{scale:1}} transition={{ease: "easeOut", duration: 0.3 }} className="gridDiv">
                            
                <Grid container>

                    <Grid item xs={12} sm={6}>
                        <div className="BeforeLogo">
                            < img src = {
                                before_after_logo
                            }
                            alt = "<-After --- Before->"
                            style = {
                                {
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                    backdropFilter: "blur(2px)",
                                    height: "40px",
                                    width: 'auto',
                                    borderRadius: "5px"
                                }
                            }
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                    </Grid>
                
                    <Grid item xs={12} sm={6} style={{marginBottom: "15px", padding: "10px"}}>
                        <SliderApp onImgChange={indexState}/>
                    </Grid>
                
                    <Grid item xs={12} sm={6} style={{backgroundColor: 'rgba(255, 255, 255, 0.5)',backdropFilter: "blur(2px)", borderRadius:"10px", padding:"10px"}}>
                        
                        <div className={classes.root}>
                        <GridList cellHeight={150}    className={classes.gridList} cols={3}>
                                {ImgData.map((tile, index) => (
                                    <GridListTile className={classes.gridList}  key={tile.after.src} cols={tile.cols || 1}>
                                        <img src={tile.after.src} alt="not loaded" onClick={() => callBackImageOnChange(index)} />
                                        <GridListTileBar title = {tile.title} />
                                    </GridListTile>
                                ))}
                        </GridList>
                        </div>

                    </Grid>
                    
                </Grid>

            </motion.div>

            </MuiThemeProvider>

        </div>
        </>
    )
    }

export default ImgApp;
