import React from 'react';
import ProgressBar from './progressBar';
import styled from 'styled-components'

const TitleDiv = styled.div `
            width: 30%;
            text-align: left;
      `

const BarDiv = styled.div `
            width: 70%;
            justify-content: right;
      `

const Box = styled.div `
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: flex-start;
            margin: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #777;
      `

export default function App(props) {

      
  return (
    <div>
      
      <Box>
            <TitleDiv>{props.skillname}</TitleDiv>
            <BarDiv>
                  <ProgressBar color={props.color} fillvalue = {props.fillvalue}/>
            </BarDiv>
      </Box>

                  

    </div>
  );
}
