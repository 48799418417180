import {Runtime, Inspector} from '@observablehq/runtime';
import React, {useEffect, useRef} from 'react';
import sunburst from '../@d3/zoomable-sunburst';
import './index.css';

export default function App() {
  const ref = useRef();

  useEffect(
    () => {
      const runtime = new Runtime();
      runtime.module(sunburst, (name) => {
        if (name === "chart") {
          return new Inspector(ref.current);
        }
      });
      return () => runtime.dispose();
    },
    []
  );

  return (
    <>
        <div className="sunStyle">
          <h2 className="suntextStyle">Skills</h2>
          <div ref={ref}/>
        </div>
    </>
  );
}
//<h2 style={textStyle}>Skills</h2>