import React from "react";
import {
  AiFillGithub,
  AiFillTwitterCircle,
  AiFillLinkedin,
  AiFillCodeSandboxCircle,
  AiFillMediumCircle,
  AiOutlineInstagram
} from "react-icons/ai";
import Icon from "./Icon";

export default function App() {
  return (
    <div className="App">
      <Icon
        passIcon={<AiFillGithub />}
        bgcolor="#333"
        link="https://github.com/ZeroPanda"
      />
      <Icon
        passIcon={<AiFillTwitterCircle />}
        bgcolor="#1da1f2"
        link="https://twitter.com/real_shrey"
      />
      <Icon
        passIcon={<AiFillLinkedin />}
        bgcolor="#0077b5"
        link="https://www.linkedin.com/in/shahshrey31/"
      />
      <Icon
        passIcon={<AiFillCodeSandboxCircle />}
        bgcolor="#333"
        link="https://codesandbox.io/u/ZeroPanda"
      />
      <Icon
        passIcon={<AiFillMediumCircle />}
        bgcolor="#00ab6c"
        link="https://medium.com/@shreyshah31"
      />
      <Icon
        passIcon={<AiOutlineInstagram />}
        bgcolor="#e1306c"
        link="https://www.instagram.com/iemshreyshah/"
      />
    </div>
  );
}
