import React, { useState, useEffect } from "react";
import Mobile from "./Mobile-Man-SVG";
import ScrolledApp from "./Mobile-Screen-SVG";
import { motion } from "framer-motion";

export default function App() {
  const [clicked, setClicked] = useState(false);
  const [width, setWidth] = useState(undefined);

  const propsHandleClicked = () => {
    setClicked(true);
  };
  const msgHandleDisappear = {
    opacity: clicked ? "0" : "1"
  };
  const Phonevariants = {
    active: {
      scale: 0,
      opacity: 0,
      backgroundColor: "rgba(255,255,255,0)"
    },
    inActive: {
      opacity: 1,
        backgroundColor: "rgba(255,255,255,0.5)",
        borderRadius: "15px",
        backdropFilter: "blur(2px)"
    }
  };

  const Scrollvariants = {
    active: {
      opacity: 1,
      scale: 1
    },
    inActive: {
      scale: 0,
      opacity: 0
    }
  };
  const mainStyle = {
    textAlign: "center",
    justifyContent: "center",
    height: "90vh",
    margin:"10px"
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth - 30);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div style={mainStyle}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <motion.div
          animate={clicked ? "active" : "inActive"}
          variants={Phonevariants}
          style={{
            transformOrigin: "center top",
            opacity: "0",
            position: "absolute",
            bottom: "20%",
            paddingTop:"20px"
          }}
        >
          <Mobile
            width={width}
            handleClicked={propsHandleClicked}
            handleDisappear={msgHandleDisappear}
          />
        </motion.div>
      </div>
      <motion.div
        animate={clicked ? "active" : "inActive"}
        variants={Scrollvariants}
        style={{ transformOrigin: "center top", opacity: 0, height: "100%" }}
      >
        <ScrolledApp />
      </motion.div>
    </div>
  );
}
