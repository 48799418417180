import React, { Component } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  TextField,
  Button,
  TextareaAutosize,
  Grid,
  Box, 
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core";
import { AiOutlineSend } from "react-icons/ai";
import "./connect.css";

const THEME = createMuiTheme({
  typography: {
    "fontFamily": "inherit"
  }
});



// Email validation
const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

// Form validation
const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // Validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // Validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === "" && (valid = false);
  });

  return valid;
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      formErrors: {
        name: "",
        email: "",
        subject: "",
        message: ""
      }
    };
  }

  toastifySuccess() {
    toast.success("Form sent!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success"
    });
  }

  toastifyFail() {
    toast.error("Form failed to send!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback fail"
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (formValid(this.state)) {
      // Handle form validation success
      const { name, email, subject, message } = this.state;

      // Set template params
      let templateParams = {
        name: name,
        email: email,
        subject: subject,
        message: message
      };

      emailjs.send("ShreyShah", "template_rxpct73", templateParams, "user_uR1jZS1eVSvnTrVjtlJOI");

      console.log(`
        --SUBMITTING--
        Name: ${name}
        Email: ${email}
        Subject: ${subject}
        Message: ${message}
      `);

      this.toastifySuccess();
      this.resetForm();
    } else {
      // Handle form validation failure
      console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
      this.toastifyFail();
    }
  };

  // Function to reset form
  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: ""
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "name":
        formErrors.name = value.length < 1 ? "Please enter your name." : "";
        break;
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "Please enter a valid email address.";
        break;
      case "subject":
        formErrors.subject = value.length < 1 ? "Please enter a subject." : "";
        break;
      case "message":
        formErrors.message = value.length < 1 ? "Please enter a message" : "";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  render() {
    const { formErrors } = this.state;

    return (
      <div className="ContactForm">
        <MuiThemeProvider theme={THEME}>
        <form id="contact-form" onSubmit={this.handleSubmit} noValidate>
          <Box mb={2}>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="name"
                  id="outlined-helperText"
                  label="Name"
                  variant="outlined"
                  value={this.state.name}
                  className={`form-control Name ${
                    formErrors.name.length > 0 ? "error" : null
                  }`}
                  onChange={this.handleChange}
                  noValidate
                ></TextField>
                {formErrors.name.length > 0 && (
                  <span className="errorMessage">{formErrors.name}</span>
                )}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  type="email"
                  name="email"
                  id="outlined-helperText"
                  label="Email"
                  variant="outlined"
                  value={this.state.email}
                  className={`form-control Email ${
                    formErrors.email.length > 0 ? "error" : null
                  }`}
                  onChange={this.handleChange}
                  noValidate
                ></TextField>
                {formErrors.email.length > 0 && (
                  <span className="errorMessage">{formErrors.email}</span>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Grid item xs={12}>
              <TextField
                type="text"
                id="outlined-helperText"
                label="Subject"
                variant="outlined"
                name="subject"
                value={this.state.subject}
                className={`form-control Subject ${
                  formErrors.subject.length > 0 ? "error" : null
                }`}
                onChange={this.handleChange}
                noValidate
              ></TextField>
              {formErrors.subject.length > 0 && (
                <span className="errorMessage">{formErrors.subject}</span>
              )}
            </Grid>
          </Box>

          <Box mb={2}>
            <Grid item xs={12}>
              <TextareaAutosize
                type="text"
                name="message"
                rowsMin={7}
                value={this.state.message}
                className={`form-control Message ${
                  formErrors.message.length > 0 ? "error" : null
                }`}
                onChange={this.handleChange}
                placeholder="Send your message..."
                noValidate
              ></TextareaAutosize>
              {formErrors.message.length > 0 && (
                <span className="errorMessage">{formErrors.message}</span>
              )}
            </Grid>
          </Box>
          <Button
            variant="contained"
            color="primary"
            endIcon={<AiOutlineSend />}
            type="submit"
          >
            Send
          </Button>
        </form>
        <ToastContainer />
        </MuiThemeProvider>
      </div>
    );
  }
}

export default ContactForm;
