import React from "react";
import { AnimateSharedLayout, AnimatePresence, motion } from "framer-motion";
import { Item } from "./Item";
import { List } from "./List";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./styles.css";

import TagManager from 'react-gtm-module'
const args = {
  gtmId: 'GTM-W8WXLHH',
  dataLayer: {
    event: "WorkVisit",
  },
  event: 'gtm.js',
  dataLayerName: "PageDataLayer"
};
TagManager.dataLayer(args);

function Store({ match }) {
  let { id } = match.params;
  const imageHasLoaded = true;

  return (
    <>
      <List selectedId={id} />
      <AnimatePresence>
        {id && imageHasLoaded && <Item id={id} key="item" />}
      </AnimatePresence>
    </>
  );
}

export default function App() {
  return (
    <motion.div initial = {{ scale: 0.2 }}
        animate = {{scale: 1 }}
        transition = {{ease: "easeOut",  duration: 0.3}}
        style = {{display: "flex",  justifyContent: "center" }} >
    <div className="container" >
      <AnimateSharedLayout type="crossfade">
        <Router>
          <Route path={["/Work/:id", "/Work/"]} component={Store} />
        </Router>
      </AnimateSharedLayout>
    </div>
    </motion.div>
  );
}
