import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { items } from "./data";
import CloseLogo from "./close.svg";

export function Item({ id }) {
  const { category, title, externalLink, referenceDate, details } = items.find(
    (item) => item.id === id
  );

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.2, delay: 0.15 }}
        style={{ pointerEvents: "auto" }}
        className="overlay"
      >
        <Link to="/Work" />
      </motion.div>
      <div className="card-content-container open">
        <motion.div className="card-content" layoutId={`card-container-${id}`}>
          <motion.div
            className="close-container overlay"
            style={{ pointerEvents: "auto" }}
          >
            <img
              src={CloseLogo}
              alt="Close Logo"
              fill="white"
              style={{ height: "100%", width: "100%" }}
            />
            <Link to="/Work" />
          </motion.div>
          <motion.div
            className="card-image-container"
            layoutId={`card-image-container-${id}`}
          >
            <img className="card-image" src={`/workimages/${id}.jpg`} alt="" />
          </motion.div>
          <motion.div
            className="title-container"
            layoutId={`title-container-${id}`}
          >
            <span className="category">{category}</span>
            <h2 className="h2">{title}</h2>
            <p style={{color:"white" , fontStyle:"italic"}}> Published on- {referenceDate}</p>
          </motion.div>
          <motion.div className="content-container" animate>
            
            <div className="p-graph">{details}</div>
            <motion.a
              href={externalLink}
              rel="noreferrer"
              target="_blank"
              animate
              className="card-link"
            >
              Find out more...
            </motion.a>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}
