import React from "react";
import "./contact.css";
import SocialApp from "./social";
import ConnectApp from "./connect";

import TagManager from 'react-gtm-module'
const args = {
  gtmId: 'GTM-W8WXLHH',
  dataLayer: {
    event: "ContactVisit",
  },
  event: 'gtm.js',
  dataLayerName: "PageDataLayer"
};
TagManager.dataLayer(args);


export default function App() {
  return (
    <div id="mainDiv">
      <div className="ContactApp">
        <div className="socialApp">
          <SocialApp />
        </div>
        <div className="connectApp">
          <ConnectApp />
        </div>
      </div>
    </div>
  );
}
