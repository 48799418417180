import React from 'react';
import ProgressBox from './progressBox';
import SkillData from './skillsdata.json';
import './scroll.css'

const divStyle = {
      border: '1px solid #777',
      margin: '5px',
      padding: '15px',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      backdropFilter: "blur(2px)",
      borderRadius: '10px',
}


const scrollDiv = {
      height: 'auto',
      maxHeight: '100vh',
      overflow: 'auto',
      fontFamily: 'Hind Vadodara',
}

export default function App() {

  return (
        <>
    <div style={scrollDiv} id="scrollStyle">
          <h2 style={divStyle}>Technical Skills</h2>
          {SkillData.map((knowledge, index) => (
                <div style={divStyle} key={index}>
                      <span style={{fontWeight:'bold'}}>{knowledge.category}</span>
                <ProgressBox indexNo={index}/>
                </div>
          ))}

    </div>
    </>
  );
}


