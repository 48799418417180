import React, { useState, useEffect } from "react";
import PCApp from "./PCApp";
import PhoneApp from "./MobileApp";

import TagManager from 'react-gtm-module'
const args = {
  gtmId: 'GTM-W8WXLHH',
  dataLayer: {
    event: "HomeVisit",
  },
  event: 'gtm.js',
  dataLayerName: "PageDataLayer"
};
TagManager.dataLayer(args);

export default function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1200;
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width < breakpoint ? <PhoneApp /> : <PCApp />;
}
